
.about-slide {
    &__img {
        width: 100%;
        padding-top: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    &:hover {
        ::v-deep {
            .swiper-control {
                opacity: 1;
                visibility: visible;
            }
        }
    }
    ::v-deep {
        .swiper-control {
            bottom: 50%;
            opacity: 0;
            visibility: hidden;
            transition: all ease-out 0.2s;
            transform: translate(-50%, -50%);
        }
    }
}
